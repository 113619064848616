import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { CSSProperties } from 'styled-components';
import { CloseIcon } from '../../common/icons/SvgInline/SvgIcons_2';
import { TAG_NAME } from '../../componentUI/constants';
import { DarkButton } from '../../componentUI/simple/Button';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';
import { useMatchMedia } from '../useMatchMedia';
import {
  CustomModalContent,
  CustomModalErrorContent,
  CustomModalErrorOverlay,
  CustomModalNpsContent,
  CustomModalOverlay,
  CustomModalPhotoContent,
  CustomModalPhotoOverlay,
  stylesForCloseButton,
  stylesForCloseButtonMobile,
  stylesForCloseButtonNps,
  TitleContainer,
} from './styled';

/**
 * Props for the styled modal component useStyledModal.
 */
export interface ModalProps extends Omit<ReactModal.Props, 'isOpen'> {
  onClose: () => void;
  children: ReactNode;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  stylesForModal?: CSSProperties;
  isBigHeight?: boolean;
  isNps?: boolean;
  isPhoto?: boolean;
  isError?: boolean;
  title?: string;
}

ReactModal.setAppElement('#root'); // required for accessibility

/**
 * Returns a styled modal and a function to toggle its visibility.
 * Accepts all parameters for ReactModal and allows changing width and height with props.
 *
 * @returns {[Component, Function]} A tuple containing the modal component and a function to externally toggle its visibility.
 *
 * External usage example:
 * ```jsx
 * const [Modal, toggleModal] = useStyledModal();
 * toggleModal(); // Open the modal in the parent component
 *
 * const onCloseModal = () => {
 *   console.log('Modal closed');
 * };
 *
 * // ...
 * return (
 *  <Modal onClose={onCloseModal}><h1>StyledModal</h1></Modal>
 * )
 * ```
 */

export const useStyledModal = (): [(props: ModalProps) => JSX.Element, () => void] => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNps, setIsNps] = useState(false);
  const { isMobile } = useMatchMedia();

  // need to disable scrolling bug, if u now how to fix this - do this
  useEffect(() => {
    if (isOpen && !isNps) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen, isNps]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const getOverlayElement = (
    props: Pick<
      React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      'key' | keyof React.HTMLAttributes<HTMLDivElement>
    > & {},
    contentElement: ReactNode,
    isPhoto: boolean,
    isError: boolean,
  ): JSX.Element => {
    if (isPhoto) {
      return <CustomModalPhotoOverlay {...props}>{contentElement}</CustomModalPhotoOverlay>;
    }

    if (isError) {
      return <CustomModalErrorOverlay {...props}>{contentElement}</CustomModalErrorOverlay>;
    }

    return <CustomModalOverlay {...props}>{contentElement}</CustomModalOverlay>;
  };

  const getContentElement = (
    props: Pick<
      React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      'key' | keyof React.HTMLAttributes<HTMLDivElement>
    > & {},
    children: ReactNode,
    {
      width,
      height,
      isBigHeight = false,
      isPhoto = false,
      isNps = false,
      isError = false,
      stylesForModal,
      title,
    }: ModalProps,
  ): JSX.Element => {
    if (isPhoto) {
      return (
        <CustomModalPhotoContent
          {...props}
          style={{ ...props.style, width, height, ...stylesForModal }}
          isBigHeight={isBigHeight}
          hasTitle={!!title}
        >
          {children}
        </CustomModalPhotoContent>
      );
    }

    if (isNps) {
      return (
        <CustomModalNpsContent
          {...props}
          style={{ ...props.style, width, height, ...stylesForModal }}
          isBigHeight={isBigHeight}
        >
          {children}
        </CustomModalNpsContent>
      );
    }

    if (isError) {
      return (
        <CustomModalErrorContent
          {...props}
          style={{ ...props.style, width, height, ...stylesForModal }}
          isBigHeight={isBigHeight}
        >
          {children}
        </CustomModalErrorContent>
      );
    }

    return (
      <CustomModalContent
        {...props}
        style={{ ...props.style, width, height, ...stylesForModal }}
        isBigHeight={isBigHeight}
      >
        {children}
      </CustomModalContent>
    );
  };

  let closeButtonStyle: CSSProperties;
  if (isNps) {
    closeButtonStyle = stylesForCloseButtonNps;
  } else if (isMobile) {
    closeButtonStyle = stylesForCloseButtonMobile;
  } else {
    closeButtonStyle = stylesForCloseButton;
  }

  const Modal = ({
    onClose,
    children,
    width,
    height,
    stylesForModal,
    isBigHeight = false,
    isNps: modalIsNps = false,
    isPhoto = false,
    isError = false,
    title,
    ...props
  }: ModalProps) => {
    useEffect(() => {
      setIsNps(modalIsNps);
    }, [modalIsNps]);

    const closeIconSize = isError || isMobile ? '28px' : '40px';

    return (
      <ReactModal
        onRequestClose={() => {
          onClose();
          toggleModal();
        }}
        shouldFocusAfterRender
        className="_"
        overlayClassName="_"
        overlayElement={
          isNps ? undefined : (props, contentElement) => getOverlayElement(props, contentElement, isPhoto, isError)
        }
        contentElement={(props, children) =>
          getContentElement(props, children, {
            width,
            height,
            isBigHeight,
            isPhoto,
            isNps,
            isError,
            stylesForModal,
            onClose,
            children,
            title,
          })
        }
        {...props}
        isOpen={isOpen}
      >
        {title && (
          <TitleContainer>
            <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5}>
              {title}
            </Title>
            <DarkButton
              onClick={() => {
                onClose();
                toggleModal();
              }}
            >
              <CloseIcon size={closeIconSize} />
            </DarkButton>
          </TitleContainer>
        )}

        {!title && (
          <DarkButton
            onClick={() => {
              onClose();
              toggleModal();
            }}
            style={closeButtonStyle}
          >
            <CloseIcon size={closeIconSize} />
          </DarkButton>
        )}
        {children}
      </ReactModal>
    );
  };

  return [Modal, toggleModal];
};
