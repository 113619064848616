import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';

import './image-gallery.scss';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { TAG_NAME } from '../../componentUI/constants';
import { ButtonV2, SecondButton } from '../../componentUI/simple/Button';
import { Text } from '../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';

import { useMatchMedia } from '../../hooks';

import { LeftNav, RightNav } from './CastumNavBtn';
import { SLIDES } from './locale';
import { FooterStyled, stylesForButtons, WrapperSlideStyled, WrapperTextContentStyled } from './styled';
import { Props } from './types';

export const OnboardingSlider = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchMedia();

  const rightNavRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => {
    if (rightNavRef.current) {
      rightNavRef.current.click();
    }
  };

  const images = SLIDES.map(({ slideDesktop, slideMobile, titleText, descriptionText }) => {
    return {
      original: `${isMobile ? slideMobile : slideDesktop}`,
      renderItem: () => (
        <WrapperSlideStyled>
          <img src={isMobile ? slideMobile : slideDesktop} alt="OnboardingSlide" />

          <WrapperTextContentStyled>
            <Title size={TITLE_SIZE.h6} tag={TAG_NAME.h6}>
              {titleText}
            </Title>

            <Text style={{ whiteSpace: 'normal' }} colorText={COLOR_MAP.text.darkGrey}>
              {descriptionText}
            </Text>
          </WrapperTextContentStyled>
        </WrapperSlideStyled>
      ),
    };
  });

  return (
    <>
      {isTablet && (
        <SecondButton onClick={onClose} style={stylesForButtons}>
          {t('onboardingSlider.closeBtn')}
        </SecondButton>
      )}

      <ImageGallery
        additionalClass="example-gallery"
        showPlayButton={false}
        showBullets
        showFullscreenButton={false}
        items={images}
        showThumbnails={false}
        renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />}
        renderRightNav={(onClick, disabled) => (
          <RightNav rightNavRef={rightNavRef} onClick={onClick} disabled={disabled} />
        )}
      />

      <FooterStyled>
        {!isTablet && (
          <SecondButton onClick={onClose} style={stylesForButtons}>
            {t('onboardingSlider.closeBtn')}
          </SecondButton>
        )}

        <ButtonV2 isStretch={isTablet} onClick={handleButtonClick} aria-label="Next Slide">
          {t('onboardingSlider.nextSlide')}
        </ButtonV2>
      </FooterStyled>
    </>
  );
};
